import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {BaseDialogTemplateComponent} from '../base-dialog-template.component';
import {Panel} from '../../../../../shared/models/visualization/panel.model';
import {VisualizationMode} from '../../../../../shared/enums/visualization-mode.enum';
import {TimeUtil} from '../../../../../shared/utils/time-util';

@Component({
  selector: 'pts-with-radar-dialog-template',
  templateUrl: './pts-with-radar-dialog-template.component.html',
  styleUrls: ['./pts-with-radar-dialog-template.component.scss']
})
export class PtsWithRadarDialogTemplateComponent extends BaseDialogTemplateComponent implements OnInit, OnDestroy {
  title: string;
  entity: any;
  name: string;
  address: any;
  period: number = 10;

  // Panel location variables
  PANEL_LOCATION_TOTAL_PASSES = 'totalPasses';
  PANEL_LOCATION_TOTAL_VIOLATION = 'totalViolation';
  PANEL_LOCATION_AVERAGE_SPEED = 'averageSpeed';
  PANEL_LOCATION_TOTAL_LANE_VIOLATION = 'totalLaneViolation';
  PANEL_LOCATION_TOTAL_PEDESTRIAN_LANE_VIOLATION = 'totalPedestrianLaneViolation';
  PANEL_LOCATION_TOTAL_SEAT_BELT_VIOLATION = 'totalSeatBeltViolation';
  PANEL_LOCATION_TOTAL_MOBILE_PHONE_USE_VIOLATION = 'totalMobilePhoneUseVıolation';


  // keeps the active tab
  activeTab: string;
  coordinate: string;

  totalPassesPanel: Panel;
  totalViolationPanel: Panel;
  averageSpeedPanel: Panel;
  totalLaneViolationPanel: Panel;
  totalPedestrianLaneViolationPanel: Panel;
  totalSeatBeltViolationPanel: Panel;
  totalMobilePhoneUseViolationPanel: Panel;


  // Declarations
  VisualizationMode = VisualizationMode;
  dialogViewportMin: number;

  summaryTabModifiedDate: string;
  totalPassesTabModifiedDate: string;
  totalNumberOfViolationTabModifiedDate: string;
  averageSpeedTabModifiedDate: string;
  totalNumberOfLaneViolationTabModifiedDate: string;
  totalNumberOfPedestrianLaneViolationTabModifiedDate: string;
  totalNumberOfSeatBeltViolationTabModifiedDate: string;
  totalNumberOfMobilePhoneUseViolationTabModifiedDate: string;

  constructor(injector: Injector) {
    super(injector);
  }

  protected mapContextData(context: any): void {
    this.entity = context.result.entity;
    this.title = context.result.entity.description.value;
    this.populateFooter();
    this.populateModifiedDatesOfTabs();
  }

  protected mapPanels() {
    this.page.panels.forEach(panel => {
      panel.ngsiContext.idQuery = this.entity.id;
      switch (panel.panelLocation) {
        case this.PANEL_LOCATION_TOTAL_PASSES:
          this.totalPassesPanel = panel;
          break;
        case this.PANEL_LOCATION_TOTAL_VIOLATION:
          this.totalViolationPanel = panel;
          break;
        case this.PANEL_LOCATION_AVERAGE_SPEED:
          this.averageSpeedPanel = panel;
          break;
        case this.PANEL_LOCATION_TOTAL_LANE_VIOLATION:
          this.totalLaneViolationPanel = panel;
          break;
        case this.PANEL_LOCATION_TOTAL_PEDESTRIAN_LANE_VIOLATION:
          this.totalPedestrianLaneViolationPanel = panel;
          break;
        case this.PANEL_LOCATION_TOTAL_SEAT_BELT_VIOLATION:
          this.totalSeatBeltViolationPanel = panel;
          break;
        case this.PANEL_LOCATION_TOTAL_MOBILE_PHONE_USE_VIOLATION:
          this.totalMobilePhoneUseViolationPanel = panel;
          break;
      }

    });
  }

  private populateModifiedDatesOfTabs() {
    const validDates: Date[] = [];
    const totalPasses = super.getValue(this.entity, 'totalNumberOfPasses', 'observedAt');
    const totalNumberOfViolation = super.getValue(this.entity, 'totalNumberOfViolation', 'observedAt');
    const averageSpeed = super.getValue(this.entity, 'averageSpeed', 'observedAt');
    const totalNumberOfLaneViolation = super.getValue(this.entity, 'totalNumberOfLaneViolation', 'observedAt');
    const totalNumberOfPedestrianLaneViolation = super.getValue(this.entity, 'totalNumberOfPedestrianLaneViolation', 'observedAt');
    const totalNumberOfSeatBeltViolation = super.getValue(this.entity, 'totalNumberOfSeatBeltViolation', 'observedAt');
    const totalNumberOfMobilePhoneUseViolation = super.getValue(this.entity, 'totalNumberOfMobilePhoneUseViolation', 'observedAt');
    if (totalPasses) {
      const totalPassesModifiedDate: Date = TimeUtil.parseISODatetime(totalPasses);
      this.totalPassesTabModifiedDate = TimeUtil.serializeCustomDatetime(totalPassesModifiedDate);
      validDates.push(totalPassesModifiedDate);
    }
    if (totalNumberOfViolation) {
      const totalNumberOfViolationModifiedDate: Date = TimeUtil.parseISODatetime(totalNumberOfViolation);
      this.totalNumberOfViolationTabModifiedDate = TimeUtil.serializeCustomDatetime(totalNumberOfViolationModifiedDate);
      validDates.push(totalNumberOfViolationModifiedDate);
    }
    if (averageSpeed) {
      const averageSpeedModifiedDate: Date = TimeUtil.parseISODatetime(averageSpeed);
      this.averageSpeedTabModifiedDate = TimeUtil.serializeCustomDatetime(averageSpeedModifiedDate);
      validDates.push(averageSpeedModifiedDate);
    }
    if (totalNumberOfLaneViolation) {
      const totalNumberOfLaneViolationModifiedDate: Date = TimeUtil.parseISODatetime(totalNumberOfLaneViolation);
      this.totalNumberOfLaneViolationTabModifiedDate = TimeUtil.serializeCustomDatetime(totalNumberOfLaneViolationModifiedDate);
      validDates.push(totalNumberOfLaneViolationModifiedDate);
    }
    if (totalNumberOfPedestrianLaneViolation) {
      const totalNumberOfPedestrianLaneViolationModifiedDate: Date = TimeUtil.parseISODatetime(totalNumberOfPedestrianLaneViolation);
      this.totalNumberOfPedestrianLaneViolationTabModifiedDate = TimeUtil.serializeCustomDatetime(totalNumberOfPedestrianLaneViolationModifiedDate);
      validDates.push(totalNumberOfPedestrianLaneViolationModifiedDate);
    }
    if (totalNumberOfSeatBeltViolation) {
      const totalNumberOfSeatBeltViolationModifiedDate: Date = TimeUtil.parseISODatetime(totalNumberOfSeatBeltViolation);
      this.totalNumberOfSeatBeltViolationTabModifiedDate = TimeUtil.serializeCustomDatetime(totalNumberOfSeatBeltViolationModifiedDate);
      validDates.push(totalNumberOfSeatBeltViolationModifiedDate);
    }
    if (totalNumberOfMobilePhoneUseViolation) {
      const totalNumberOfMobilePhoneUseViolationModifiedDate: Date = TimeUtil.parseISODatetime(totalNumberOfMobilePhoneUseViolation);
      this.totalNumberOfMobilePhoneUseViolationTabModifiedDate = TimeUtil.serializeCustomDatetime(totalNumberOfMobilePhoneUseViolationModifiedDate);
      validDates.push(totalNumberOfMobilePhoneUseViolationModifiedDate);
    }

    if (validDates.length) {
      this.summaryTabModifiedDate = TimeUtil.serializeCustomDatetime(
        validDates.reduce((nearestDate, date) => {
          if (date >= nearestDate) {
            return date;
          }
          return nearestDate;
        }, validDates[0]));
    }
  }

  /**
   * Sets the active tab based on the tab selection event.
   * @param event the tab selection event
   * */
  public onTabChange(event) {
    this.activeTab = event.tabId;
  }

  private populateFooter() {
    this.coordinate = this.entity.location.value.coordinates[0] + ' , ' + this.entity.location.value.coordinates[1];
  }
}
