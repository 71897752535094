import {environment} from '../../../environments/environment';

export enum UserRole {
  REALM_MANAGER = 'realm-manager',
  PLATFORM_MANAGER = 'platform-manager',
  DOMAIN_MANAGER = 'domain-manager',
  ASELSAN_USER = 'aselsan-user',
  REGULAR_USER = 'regular-user',
  DISTRIBUTOR_USER = 'distributor-user',
  TEST_USER = 'test-user',
}

export class UserRoleUtil {

  /**
   * Returns the available roles for the given realm id.
   * @param realmId the id of realm
   * @return the list of available roles for the given realm
   */
  // TODO Taglere göre değiştirilecek
  public static getRoles(realmId: string): UserRole[] {
    if (realmId === environment.auth.master_realm_id) {
      return [UserRole.DOMAIN_MANAGER, UserRole.PLATFORM_MANAGER];
    } else if (realmId.includes('devicemanagement')) {
      return [UserRole.ASELSAN_USER];
    } else if (realmId.includes('healthcare') || (realmId.includes('universiteler'))) {
      return [UserRole.ASELSAN_USER, UserRole.DISTRIBUTOR_USER, UserRole.REGULAR_USER];
    } else {
      return [UserRole.DOMAIN_MANAGER, UserRole.REALM_MANAGER];
    }
  }

  /**
   * Returns whether the user with given role needs to be assigned to some domains.
   * @param role the role of user
   * @return whether the user with given role needs to be assigned to some domains.
   */
  public static doesRoleNeedDomains(role: UserRole): boolean {
    return role === UserRole.DOMAIN_MANAGER;
  }

  /**
   *  Extracts and returns the user roles from the given token.
   *  @param jwt user token
   *  @return the user role list
   * */
  public static extractRolesFromToken(jwt: any): string[] {
    const availableRoles: string[] = this.getAvailableRoles();
    return jwt.realm_access.roles.filter(role => availableRoles.includes(role));
  }

  /**
   * Returns the available roles in the platform as string list.
   * @return available roles as string list
   * */
  private static getAvailableRoles(): string[] {
    return Object.keys(UserRole).map(key => UserRole[key]);
  }
}
