<closeable-dialog class="pts-with-radar-dialog-container">
  <div class="dialog-header" dialog-card-header> <label>{{ 'PTS With Radar' | translate }} - {{title}} </label></div>

  <div dialog-card-body class="dialog-body full-height full-width">
    <div class="centered-image">
      <img alt="Pts Radar Image" class="img1" src="assets/images/ptsRadar.png" width="590" height="200"  />
    </div>
    <nb-tabset (changeTab)="onTabChange($event)">
      <nb-tab tabTitle="{{ 'Summary' | translate }}" [active]="activeTab === 'summary'" [tabId]="'summary'">
        <div class="summary">
          <div class="row row-title">
            <div class="col-4 column-title">
              {{ 'Current Measurements' | translate }} ({{'Last'|translate}} {{period}} {{'timeInterval_m'|translate}}) :
            </div>
          </div>
          <div class="row value-container">
            <div class="value-box">
              <div class="value-title">{{ 'Total Passes'|translate }}</div>
              <div class="value">{{ getValue(entity, 'totalNumberOfPasses') }}</div>
            </div>
            <div class="value-box">
              <div class="value-title">{{ 'Total Violation'|translate }}</div>
              <div class="value">{{ getValue(entity, 'totalNumberOfViolation') }}</div>
            </div>
            <div class="value-box">
              <div class="value-title">{{ 'Average Speed'|translate }}</div>
              <div class="value">{{ getValue(entity, 'averageSpeed') }} {{'unit.km/h'| translate}}</div>
            </div>
            <div class="value-box">
              <div class="value-title">{{ 'Total Lane Violation'|translate }}</div>
              <div class="value">{{ getValue(entity, 'totalNumberOfLaneViolation') }}</div>
            </div>
            <div class="value-box">
              <div class="value-title">{{ 'Total Pedestrian Lane Violation'|translate }}</div>
              <div class="value">{{ getValue(entity, 'totalNumberOfPedestrianLaneViolation') }}</div>
            </div>
            <div class="value-box">
              <div class="value-title">{{ 'Total Seat Belt Violation'|translate }}</div>
              <div class="value">{{ getValue(entity, 'totalNumberOfSeatBeltViolation') }}</div>
            </div>
            <div class="value-box">
              <div class="value-title">{{ 'Total Mobile Phone Use Violation'|translate }}</div>
              <div class="value">{{ getValue(entity, 'totalNumberOfMobilePhoneUseViolation') }}</div>
            </div>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="{{'Total Passes'|translate}} " [active]="activeTab === 'totalPasses'"  [tabId]="'totalPasses'">
        <div class="observation-title"><span>{{'Total Passes'|translate}}</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="totalPassesPanel" [displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
      <nb-tab tabTitle="{{'Total Violation'|translate}}">
        <div class="observation-title"><span>{{'Total Violation'|translate}}</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="totalViolationPanel" [displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
      <nb-tab tabTitle="{{'Average Speed'|translate}}">
        <div class="observation-title"><span>{{'Average Speed'|translate}}</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="averageSpeedPanel" [displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
      <nb-tab tabTitle="{{'Total Lane Violation'|translate}}">
        <div class="observation-title"><span>{{'Total Lane Violation'|translate}}</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="totalLaneViolationPanel" [displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
      <nb-tab tabTitle="{{'Total Pedestrian Lane Violation'|translate}}">
        <div class="observation-title"><span>{{'Total Pedestrian Lane Violation'|translate}}</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="totalPedestrianLaneViolationPanel" [displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
      <nb-tab tabTitle="{{'Total Seat Belt Violation'|translate}}">
        <div class="observation-title"><span>{{'Total Seat Belt Violation'|translate}}</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="totalSeatBeltViolationPanel" [displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
      <nb-tab tabTitle="{{'Total Mobile Phone Use Violation'|translate}}">
        <div class="observation-title"><span>{{'Total Mobile Phone Use Violation'|translate}}</span> {{'Change'|translate}}</div>
        <nb-tabset>
          <nb-tab tabTitle="{{'Last 48 hours'|translate}}">
            <uruk-panel [panel]="totalMobilePhoneUseViolationPanel" [displayTitle]="false" [visualizationMode]="VisualizationMode.Dialog" [pageId]="page.id" [dialogViewportMin]="dialogViewportMin"></uruk-panel>
          </nb-tab>
        </nb-tabset>
      </nb-tab>
    </nb-tabset>
  </div>

  <div *ngIf="entity" dialog-card-footer class="dialog-footer">
    <div class="dialog-footer-left">
      {{'coordinate' | translate}} : {{coordinate}}
    </div>
    <div class="dialog-footer-right">
      {{'Last Update Time' | translate}} :
      <ng-container [ngSwitch]="activeTab">
        <ng-container *ngSwitchCase="'totalPasses'">
          <ng-container *ngIf="totalPassesTabModifiedDate">
            {{ totalPassesTabModifiedDate }}
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'totalViolation'">
          <ng-container *ngIf="totalNumberOfViolationTabModifiedDate">
            {{ totalNumberOfViolationTabModifiedDate }}
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'averageSpeed'">
          <ng-container *ngIf="averageSpeedTabModifiedDate">
            {{ averageSpeedTabModifiedDate }}
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'totalLaneViolation'">
          <ng-container *ngIf="totalNumberOfLaneViolationTabModifiedDate">
            {{ totalNumberOfLaneViolationTabModifiedDate }}
          </ng-container>
        </ng-container><ng-container *ngSwitchCase="'totalPedestrianLaneViolation'">
        <ng-container *ngIf="totalNumberOfPedestrianLaneViolationTabModifiedDate">
          {{ totalNumberOfPedestrianLaneViolationTabModifiedDate }}
        </ng-container>
      </ng-container>
        <ng-container *ngSwitchCase="'totalSeatBeltViolation'">
          <ng-container *ngIf="totalNumberOfSeatBeltViolationTabModifiedDate">
            {{ totalNumberOfSeatBeltViolationTabModifiedDate }}
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'totalMobilePhoneUseVıolation'">
          <ng-container *ngIf="totalNumberOfMobilePhoneUseViolationTabModifiedDate">
            {{ totalNumberOfMobilePhoneUseViolationTabModifiedDate }}
          </ng-container>
        </ng-container>


        <ng-container *ngSwitchDefault>
          <ng-container *ngIf="summaryTabModifiedDate">
            {{ summaryTabModifiedDate }}
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

</closeable-dialog>
